<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <div class="bg-light-primary rounded-top text-center">
      <b-img
        :src="require('@/assets/images/illustration/email.svg')"
        alt="Meeting Pic"
        height="80"
      />
    </div>
    <b-card-body>

      <template v-if="evento && evento.location">
        <!-- header -->
        <div class="meetup-header d-flex align-items-center">
          <div class="meetup-day">
            <h6 class="mb-0">
              {{ diaSemanaEvento }}
            </h6>
            <h3 class="mb-0">
              {{ diaEvento }}
            </h3>
          </div>
          <div class="my-auto">
            <b-card-title class="mb-25">
              Plantão de Vendas
            </b-card-title>
            <b-card-text class="mb-0">
              {{ evento.description }}
            </b-card-text>
          </div>
        </div>

        <!-- Data do Evento  -->
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar
              rounded
              variant="light-primary"
              size="34"
            >
              <feather-icon
                icon="CalendarIcon"
                size="18"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">
              {{ diaEventoExtenso }}
            </h6>
            <small>{{ horaEventoExtenso }}</small>
          </b-media-body>
        </b-media>      

        <!-- Local do Evento  -->
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar
              rounded
              variant="light-primary"
              size="34"
            >
              <feather-icon
                icon="MapPinIcon"
                size="18"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">
              {{ evento.location.name }}
            </h6>
            <small>{{ localEvento }}</small>
          </b-media-body>
        </b-media>      

        <!-- Participantes -->
        <b-avatar-group
          class="mt-2 pt-50"
          size="33"
        >
          <b-avatar
            v-for="usuario in evento.events_users"
            :key="usuario.user.uuid_user"
            v-b-tooltip.hover.bottom="usuario.user.user_fullname"
            :text="avatarText(usuario.user.user_fullname)"
            class="pull-up"
          />
        </b-avatar-group>
      </template>

      <!-- Sem eventos na agenda -->
      <template v-else>
        <div class="text-center">
          <h5 class="mt-1">
              Nenhum Plantão Agendado
          </h5>
        </div>
      </template>

    </b-card-body>
  </b-card>
</template>

<script>
import { avatarText } from "@core/utils/filter";
import gql from "graphql-tag"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'
import 'moment-timezone';
import 'moment/locale/pt-br';
import store from '@/store'
import {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    VBTooltip,
    BMediaBody,
} from 'bootstrap-vue'

// converte primeira letra para maiusculo no Moment JS
var meses = 'Janeiro_Fevereiro_Março_Abril_Maio_Junho_Julho_Agosto_Setembro_Outubro_Novembro_Dezembro'.split('_');
var mesesAbr = 'Jan_Fev_Mar_Abr_Mai_Jun_Jul_Ago_Set_Out_Nov_Dez'.split('_');
var diasSemana = 'Domingo_Segunda_Terça_Quarta_Quinta_Sexta_Sábado'.split('_');
var diasSemanaAbr = 'Dom_Seg_Ter_Qua_Qui_Sex_Sáb'.split('_');

moment.updateLocale('pt-br', { 
  months : meses, 
  monthsShort: mesesAbr,
  weekdays : diasSemana,
  weekdaysShort: diasSemanaAbr
});

export default {
    components: {
        BCard,
        BImg,
        BCardBody,
        BCardText,
        BCardTitle,
        BMedia,
        BMediaAside,
        BAvatar,
        BAvatarGroup,
        BMediaBody,
    },

    directives: {
        'b-tooltip': VBTooltip,
    },

    data() {
        return {
            evento: {},
            avatarText,
        }
    },

    computed: {
        diaEvento: function () {
            return moment(this.evento.date).format('DD') || ''
        },
        diaSemanaEvento: function () {
            return moment(this.evento.date).format('ddd').toUpperCase() || ''
        },
        diaEventoExtenso: function () {
            return moment(this.evento.date).format('dddd, DD [de] MMMM [de] YYYY') || ''
        },
        horaEventoExtenso: function () {
            if (this.evento.allDay) {
                return 'Dia Todo'
            }
            else {
              var horaIni = moment(this.evento.date).format('HH:mm') || ''
              var horaFin = moment(this.evento.end_date).format('HH:mm') || ''
              return horaIni + ' às ' + horaFin
            }
        },
        localEvento: function () {
            return this.evento.location.city.name + ' - ' + this.evento.location.city.state.uf
        },
        loggedUser() {
            if (store.state.user.loggedUser) {
                return store.state.user.loggedUser
            }
            else {
                return null
            }
        },
    },

    created() {
      if(this.loggedUser){
        this.getEvento();
      }
    },

    methods: {

        async getEvento () {
            this.$apollo.query({
                query: gql`
                    query getMyEvents($uuid_user: uuid, $dataini: timestamp) {
                        events(
                            where: {
                                date: {_gte: $dataini},
                                events_users: {user_uuid: {_eq: $uuid_user }}
                            },
                            order_by: {date: asc},
                            limit: 1
                        ) {
                            title
                            date
                            end_date
                            allDay
                            description
                            location {
                                uuid_location
                                name
                                city {
                                    name
                                    state {
                                        uf
                                        name
                                    }
                                }
                            }
                            events_users {
                                user {
                                    uuid_user
                                    user_first_name
                                    user_last_name
                                    user_fullname
                                }
                            }
                        }
                    }
                `,
                variables: {
                    dataini: moment().format('YYYY-MM-DD'),
                    uuid_user: this.loggedUser.uuid_user
                },
                fetchPolicy: 'no-cache' //'cache-first ou no-cache'
            })                
            .then((data) => {
                this.evento = data["data"]["events"][0]
            })
            .catch(error => {
                this.showToast({
                    title: 'Erro!',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: 'Erro ao obter Agenda! ' + error,
                }, 3000);
            })               
        },

        showToast(toast, duration) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: toast.title,
                    icon: toast.icon,
                    variant: toast.variant,
                    text: toast.text
                },
            },
                {
                    timeout: duration
                });
        },         

    },
}
</script>
