<template>
  <b-card 
    body-class="pb-50"
  >
    <h4 class="font-weight-bolder mb-2">
      Histórico de Vendas (12 meses)
    </h4>     
    <vue-apex-charts
      height="270"
      :options="statisticsOrder.chartOptions"
      :series="statisticsOrder.series"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import gql from "graphql-tag"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from "@/store"
export default {

    components: {
        BCard,
        VueApexCharts,
    },

    data() {
        return {
            statisticsOrder: {
                series: [
                  {
                    name: 'Vendas',
                    data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                  },
                ],
                chartOptions: {
                    chart: {
                        type: 'bar',
                        stacked: true,
                        toolbar: {
                          show: false,
                        },
                    },
                    grid: {
                        show: true,
                        padding: {
                          left: 0,
                          right: 0,
                          top: -40,
                          bottom: -10,
                        },
                    },
                    plotOptions: {
                      bar: {
                          horizontal: false,
                          columnWidth: '40%',
                          startingShape: 'rounded',
                          colors: {
                            // backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor, $barColor, $barColor],
                            // backgroundBarRadius: 5,
                          },
                      },
                    },
                    legend: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    colors: [$themeColors.success],

                    xaxis: {
                        categories: ['', '', '', '', '', '', '', '', '', '', '', ''],
                        labels: {
                          show: true,
                          style: {
                            colors: [
                              '#4f5d9b', '#4f5d9b', '#4f5d9b', '#4f5d9b', '#4f5d9b', '#4f5d9b',
                              '#4f5d9b', '#4f5d9b', '#4f5d9b', '#4f5d9b', '#4f5d9b', '#4f5d9b'
                            ]
                          }
                        },
                        axisBorder: {
                          show: true,
                        },
                        axisTicks: {
                          show: false,
                        },
                    },
                    yaxis: {
                        show: false,
                    },
                    tooltip: {
                        x: {
                          show: false,
                        },
                    },
                },
            },
        }
    },

    created() {
      if(this.loggedUser){
        this.getHistorico();
      }
    },

    methods: {
        async getHistorico () {
            this.$apollo.query({
                query: gql`
                  query getHistoricoVenda($codcfo: String) {
                    api_view_portal_corretor_vendas_historico(
                      where: {corretor: {_eq: $codcfo}}, 
                      order_by: {ano: asc, mes: asc}
                    ) {
                      ano
                      mes
                      mes_abr
                      quantidade_venda
                    }
                  }               
                `,
                variables: {
                    codcfo: this.loggedUser.codcfo
                },
                fetchPolicy: 'no-cache' //'cache-first ou no-cache'
            })                
            .then((data) => {

                let historico = data["data"]["api_view_portal_corretor_vendas_historico"]
                let meses = historico.map(item => { return item.mes_abr })
                let vendas = historico.map(item => { return item.quantidade_venda })

                this.statisticsOrder.chartOptions = {...this.chartOptions, ...{
                    xaxis: {
                        categories: meses,
                        labels: {
                          show: true,
                        },
                        axisBorder: {
                          show: true,
                        },
                        axisTicks: {
                          show: false,
                        },
                    }
                }}

                this.statisticsOrder.series = [{
                    name: 'Vendas',
                    data: vendas
                }] 

            })
            .catch(error => {
                this.showToast({
                    title: 'Erro!',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: 'Erro ao obter Histórico! ' + error,
                }, 3000);
            })               
        },

        showToast(toast, duration) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: toast.title,
                    icon: toast.icon,
                    variant: toast.variant,
                    text: toast.text
                },
            },
                {
                    timeout: duration
                });
        },        

    },
    computed: {
        loggedUser() {
            if (store.state.user.loggedUser) {
                return store.state.user.loggedUser
            }
            else {
                return null
            }
        },
    }
}
</script>
