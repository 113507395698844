<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>
        <b-skeleton
          v-if="tituloPeriodo == null"
          animation="fade"
          style="margin-top: 0.5rem; margin-bottom: 0.5rem"
        >
        </b-skeleton>
        <span v-else class="font-weight-bolder mb-0">
          Vendas - {{ tituloPeriodo }}
        </span>           
      </b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.icon"
          md="4"
          sm="6"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <b-skeleton
                v-if="item.title == null"
                animation="fade"
                style="margin-top: 0.5rem; margin-bottom: 0.5rem"
              >
              </b-skeleton>
              <h4 v-else class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>              
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import gql from "graphql-tag"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BSkeleton
} from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BSkeleton
    },
    data() {
        return {
            tituloPeriodo: null,

            statisticsItems: [
                {
                    icon: 'TrendingUpIcon',
                    color: 'light-primary',
                    title: null,
                    subtitle: 'Vendas',
                    customClass: '',
                },
                {
                    icon: 'DollarSignIcon',
                    color: 'light-success',
                    title: null,
                    subtitle: 'Valor de Venda',
                    customClass: '',
                },
                {
                    icon: 'PercentIcon',
                    color: 'light-info',
                    title: null,
                    subtitle: 'Comissão Gerada',
                    customClass: '',
                }                
            ],
        }
    },

    created() {
      if(this.loggedUser){
        this.getIndVendas();
      }
       // this.getIndDistratos();
    },

    methods: {

        async getIndVendas () {
            this.$apollo.query({
                query: gql`
                  query getIndVendas($codcfo: String) {
                    api_view_portal_corretor_ind_vendas_mes(
                        where: { corretor: {_eq: $codcfo}}
                    ) {
                      periodo,
                      quantidade_vendas_mes,
                      valor_vendas_mes,
                      valor_comissao_mes
                    }
                  }                
                `,
                variables: {
                    codcfo: this.loggedUser.codcfo
                },
                fetchPolicy: 'no-cache' //'cache-first ou no-cache'
            })                
            .then((data) => {
                let vendas_quantidade = data["data"]["api_view_portal_corretor_ind_vendas_mes"][0]["quantidade_vendas_mes"] || 0
                let vendas_valor = this.formatPrice(data["data"]["api_view_portal_corretor_ind_vendas_mes"][0]["valor_vendas_mes"] || 0, 2)
                let comissao_valor = this.formatPrice(data["data"]["api_view_portal_corretor_ind_vendas_mes"][0]["valor_comissao_mes"] || 0, 2)
                let periodo = data["data"]["api_view_portal_corretor_ind_vendas_mes"][0]["periodo"] || ''
                this.tituloPeriodo = periodo.charAt(0).toUpperCase() + periodo.slice(1)
                this.statisticsItems[0].title = vendas_quantidade
                this.statisticsItems[1].title = vendas_valor
                this.statisticsItems[2].title = comissao_valor
            })
            .catch(error => {
                this.showToast({
                    title: 'Erro!',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: 'Erro ao obter Indicadores! ' + error,
                }, 3000);
            })               
        },

        async getIndDistratos () {
            this.$apollo.query({
                query: gql`
                  query getIndDistratos($codcfo: String) {
                    api_view_portal_corretor_ind_distratos_mes(
                        where: { corretor: {_eq: $codcfo}}
                    ) {
                      quantidade_distratos_mes
                    }
                  }                
                `,
                variables: {
                    codcfo: this.loggedUser.codcfo
                },
                fetchPolicy: 'no-cache' //'cache-first ou no-cache'
            })                
            .then((data) => {
                let distratos_quantidade = data["data"]["api_view_portal_corretor_ind_distratos_mes"][0]["quantidade_distratos_mes"] || 0
                this.statisticsItems[2].title = distratos_quantidade
            })
            .catch(error => {
                this.showToast({
                    title: 'Erro!',
                    icon: 'XIcon',
                    variant: 'danger',
                    text: 'Erro ao obter Indicadores! ' + error,
                }, 3000);
            })               
        },

        formatPrice(value, decimals) {
            let val = (value/1).toFixed(decimals).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },        

        showToast(toast, duration) {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: toast.title,
                    icon: toast.icon,
                    variant: toast.variant,
                    text: toast.text
                },
            },
                {
                    timeout: duration
                });
        },        

    },
    computed: {
        loggedUser() {
            if (store.state.user.loggedUser) {
                return store.state.user.loggedUser
            }
            else {
                return null
            }
        },   
    }
}
</script>
