<template>
    <b-card no-body class="card-statistics">
        <b-card-header>
            <b-card-title>
                <span class="font-weight-bolder mb-0"> Comissões Acumuladas </span>
            </b-card-title>
        </b-card-header>
        <b-card-body class="statistics-body">
            <b-row>
                <b-col
                    :key="statisticsItems.icon"
                    md="12"
                    sm="12"
                    class="mb-2 mb-md-0"
                    :class="statisticsItems.customClass"
                >
                    <b-media no-body>
                        <b-media-aside class="mr-2">
                            <b-avatar size="48" :variant="statisticsItems.color">
                                <feather-icon size="24" :icon="statisticsItems.icon" />
                            </b-avatar>
                        </b-media-aside>
                        <b-media-body class="my-auto">
                            <b-skeleton
                                v-if="statisticsItems.title == null"
                                animation="fade"
                                style="margin-top: 0.5rem; margin-bottom: 0.5rem"
                            >
                            </b-skeleton>
                            <h4 v-else class="font-weight-bolder mb-0">
                                {{ statisticsItems.title }}
                            </h4>
                            <b-card-text class="font-small-3 mb-0">
                                {{ statisticsItems.subtitle }}
                            </b-card-text>
                        </b-media-body>
                    </b-media>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import store from "@/store"
import services from "@/services"
import formatValue from "@/utils/formatValue"

import {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BSkeleton
} from 'bootstrap-vue'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BSkeleton
    },
    data() {
        return {
            tituloPeriodo: null,

            statisticsItems: {
                icon: 'PercentIcon',
                color: 'light-success',
                title: null,
                subtitle: 'Valor Previsto',
                customClass: '',
            }
        }
    },

    created() {
        this.getComissoes();
    },

    methods: {
        async getComissoes () {
            if(this.loggedUser){
                await services.dashboard.getComissoes(this.loggedUser.codcfo)
                if(this.comissoes){
                    this.statisticsItems.title = formatValue.formatPrice(this.comissoes || 0, 2);
                }
                else {
                    this.statisticsItems.title = 0;
                }
            }
        }
    },

    watch: {
        comissoes: {
            async handler() {
                this.getComissoes();
            }
        }
    },

    computed: {
        comissoes() {
            if(store.state.vendas.comissoes){
                return store.state.vendas.comissoes;
            }
            else {
                return null;
            }
        },

        loggedUser() {
            if(store.state.user.loggedUser){
                return store.state.user.loggedUser
            }
            else {
                return null
            }
        },
    }
}
</script>
