import {
    BCard,
    BRow,
    BCardHeader,
    BCol,
    BCardTitle,
    BFormInput,
    BButton, BCarousel, BCarouselSlide,
    BTable,
    BContainer,
    BMediaAside, BMediaBody,
    BSkeleton,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BCardText,
    BInputGroupAppend,
    BFormGroup, BFormSelect,
    BDropdownItem,
    BInputGroup,
    BFormCheckbox,
    BInputGroupPrepend,
    BCardBody, VBPopover,
    BPagination,
} from "bootstrap-vue";

import vSelect from "vue-select";
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { avatarText } from "@core/utils/filter";
import { CarouselCard, CarouselCardItem } from 'vue-carousel-card'
import 'vue-carousel-card/styles/index.css'
import Vue from 'vue'
import vuescroll from 'vuescroll'
import CardEvolucaoVendas from '../../../dashboards/CardEvolucaoVendas'
import CardVendasMes from '../../../dashboards/CardVendasMes'
import CardComissoes from '../../../dashboards/CardComissoes'
import CardEventoCalendario from '../../../dashboards/CardEventoCalendario'

Vue.use(vuescroll)

export default {

    components: {
        vuescroll,
        BSkeleton,
        BContainer,
        BCardHeader,
        BMediaAside,
        BMediaBody,
        CarouselCard,
        CarouselCardItem,
        BCardText,
        BCard,
        BCardTitle,
        BRow,
        BCol,
        BFormInput,
        BCardBody,
        BCarousel,
        BCarouselSlide,
        BCardCode,
        BFormCheckbox,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BFormGroup, BFormSelect,
        BLink,
        BInputGroupAppend,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BInputGroup,
        BInputGroupPrepend,
        vSelect,
        CardEvolucaoVendas,
        CardVendasMes,
        CardEventoCalendario,
        CardComissoes
    },

    directives: {
        'b-popover': VBPopover,
    },

    data() {
        return {
            dashboards: null,
            vCommunicated: '',
        }
    },

    async created() {

    },

    methods: {

    },

    setup() {
        return {
            avatarText,
        }
    }
}